<template>
  <div>
    <b-alert
      :show="typeof errors === 'string'"
      variant="danger"
      fade
    >
      {{ errors }}
    </b-alert>
    <template v-if="typeof errors == 'object'">
      <b-alert
        v-for="(error, i) in errors"
        :key="i"
        :show="Boolean(i)"
        variant="danger"
        fade
      >
        {{ error[0] }}
      </b-alert>
    </template>
  </div>
</template>

<script>
  export default {
    name: "Error",
    props: ['errors']
  }
</script>
