<template>
  <div class="single-form-container position-relative">
    <error :errors="settingError" />
    <b-overlay :show="formBusy" rounded="sm">
    <portlet :title="title">
      <template v-slot:body>
        <b-form ref="singleSetting" @submit.stop.prevent="onSubmit" @reset="onReset">
          <b-row class="row">
            <b-col sm="6" md="4">
              <b-form-group
                id="input-group-1"
                :state="validateState('name')"
                aria-describedby="name-live-feedback"
                label="تەڭشەك نامى"
                label-for="name"
                description="تەڭشەكنىڭ بىردىنبىر نامىنى كىرگۈزۈڭ"
              >
                <b-form-input
                  id="name"
                  :state="validateState('name')"
                  aria-describedby="name-live-feedback"
                  size="md"
                  style="direction: ltr;"
                  v-model="formData.name"
                  type="text"
                  placeholder=" تەڭشەك نامىنى كىرگۈزۈڭ"
                ></b-form-input>
                <b-form-invalid-feedback id="name-live-feedback">
                  تەڭشەكنىڭ بىردىنبىر نامىنى كىرگۈزۈڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6" md="4">
              <b-form-group
                id="input-group-2"
                :state="validateState('title')"
                aria-describedby="title-live-feedback"
                label="تەڭشەك ماۋزوسى"
                label-for="title"
                description="تەڭشەكنىڭ ماۋزوسىنى كىرگۈزۈڭ"
              >
                <b-form-input
                  id="title"
                  :state="validateState('title')"
                  aria-describedby="title-live-feedback"
                  size="md"
                  v-model="formData.title"
                  type="text"
                  placeholder=" تەڭشەك ماۋزوسىنى كىرگۈزۈڭ"
                ></b-form-input>
                <b-form-invalid-feedback id="title-live-feedback">
                  تەڭشەكنىڭ ماۋزوسىنى كىرگۈزۈڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col sm="6" md="4">
              <b-form-group
                      id="input-group-3"
                      :state="validateState('remark')"
                      aria-describedby="remark-live-feedback"
                      label="تەڭشەك چۈشەنچىسى"
                      label-for="remark"
                      description="تەڭشەكنىڭ قىسقىچە چۈشەندۈرۈلىشىنى كىرگۈزۈڭ"
              >
                <b-form-input
                        id="remark"
                        :state="validateState('remark')"
                        aria-describedby="remark-live-feedback"
                        size="md"
                        v-model="formData.remark"
                        type="text"
                        placeholder=" تەڭشەكنىڭ قىسقىچە چۈشەندۈرۈلىشىنى كىرگۈزۈڭ"
                ></b-form-input>
                <b-form-invalid-feedback id="remark-live-feedback">
                  تەڭشەكنىڭ قىسقىچە چۈشەندۈرۈلىشىنى كىرگۈزۈڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col sm="6" md="6">
              <b-form-group
                id="input-group-4"
                :state="validateState('type')"
                aria-describedby="type-live-feedback"
                label="تەڭشەك تىپى"
                label-for="type"
                description="تەڭشەكنىڭ تىپىنى تاللاڭ"
              >
                <b-form-radio-group :state="validateState('type')" aria-describedby="type-live-feedback" @input="checkTheTypeValue" id="type" v-model="formData.type" :options="formType" buttons name="radios-btn-default" />
                <b-form-invalid-feedback id="type-live-feedback">
                  تەڭشەكنىڭ تىپىنى تاللاڭ
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
          </b-row>
          <template v-if="formData.type === 'input'">
            <b-row>
              <b-col sm="6" md="4">
                <b-form-group
                        id="input-value-input"
                        :state="validateState('value')"
                        aria-describedby="value-live-feedback"
                        label="تەڭشەك قىممىتى"
                        label-for="value"
                        description="تەڭشەكنىڭ قىممىتىنى كىرگۈزۈڭ"
                >
                  <b-form-input
                          id="value"
                          :state="validateState('value')"
                          aria-describedby="value-live-feedback"
                          size="md"
                          v-model="formData.value"
                          type="text"
                          placeholder=" تەڭشەك قىممىتىنى كىرگۈزۈڭ"
                  ></b-form-input>
                  <b-form-invalid-feedback id="value-live-feedback">
                    تەڭشەكنىڭ قىممىتىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <template v-if="formData.type === 'radio'">
            <b-row>
              <b-col sm="6" md="4">
                <b-form-group
                        id="radio-value-source"
                        :state="validateState('source')"
                        aria-describedby="source-live-feedback"
                        label="تەڭشەك جەدىۋەل قىممىتى"
                        label-for="source"
                        description="تەڭشەكنىڭ جەدىۋەل قىممەت ئۇچۇرىنى كىرگۈزۈڭ"
                >
                  <b-textarea :state="validateState('source')" aria-describedby="source-live-feedback" trim @blur="generateDynamicType" v-model="formData.source" placeholder="بىر قۇرغا بىر قىممەت كەلسۇن، قىممەت ھاسىللاش شەكلى بولسا، key:value شەكلىدەك بولسۇن" />
                  <b-form-invalid-feedback id="source-live-feedback">
                    تەڭشەكنىڭ جەدىۋەل قىممەت ئۇچۇرىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col v-if="dynamicType" sm="6" md="4">
                <b-form-group
                        :state="validateState('value')"
                        aria-describedby="value-live-feedback"
                        id="input-value-radio"
                        label="تەڭشەك قىممىتى"
                        label-for="value"
                        description="تەڭشەكنىڭ قىممىتىنى تاللاڭ"
                >
                  <b-form-radio-group id="value" :state="validateState('value')" aria-describedby="value-live-feedback" v-model="formData.value" :options="dynamicType" buttons name="radios-btn-default" />
                  <b-form-invalid-feedback id="value-live-feedback">
                    تەڭشەكنىڭ قىممىتىنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <template v-if="formData.type === 'checkbox'">
            <b-row>
              <b-col sm="6" md="4">
                <b-form-group
                        id="checkbox-value-source"
                        :state="validateState('source')"
                        aria-describedby="source-live-feedback"
                        label="تەڭشەك جەدىۋەل قىممىتى"
                        label-for="source"
                        description="تەڭشەكنىڭ جەدىۋەل قىممەت ئۇچۇرىنى كىرگۈزۈڭ"
                >
                  <b-textarea :state="validateState('source')" aria-describedby="source-live-feedback" trim @blur="generateDynamicType" v-model="formData.source" placeholder="بىر قۇرغا بىر قىممەت كەلسۇن، قىممەت ھاسىللاش شەكلى بولسا، key:value شەكلىدەك بولسۇن" />
                  <b-form-invalid-feedback id="source-live-feedback">
                    تەڭشەكنىڭ جەدىۋەل قىممەت ئۇچۇرىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
              <b-col v-if="dynamicType" sm="6" md="4">
                <b-form-group
                        id="input-value-checkbox"
                        label="تەڭشەك قىممىتى"
                        label-for="json_value"
                        description="تەڭشەكنىڭ قىممىتىنى تاللاڭ"
                >
                  <b-form-checkbox-group id="json_value" v-model="formData.json_value" :options="dynamicType" buttons name="radios-btn-default" />
                  <b-form-invalid-feedback id="value-live-feedback">
                    تەڭشەكنىڭ قىممىتىنى تاللاڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <template v-if="formData.type === 'textarea'">
            <b-row>
              <b-col sm="6" md="4">
                <b-form-group
                        id="input-value-textarea"
                        :state="validateState('value')"
                        aria-describedby="value-live-feedback"
                        label="تەڭشەك قىممىتى"
                        label-for="value"
                        description="تەڭشەكنىڭ قىممىتىنى كىرگۈزۈڭ"
                >
                  <b-textarea :state="validateState('value')" aria-describedby="value-live-feedback" id="value" trim v-model="formData.value" placeholder="تەڭشەك قىممىتىنى كىرگۈزۈڭ" />
                  <b-form-invalid-feedback id="value-live-feedback">
                    تەڭشەكنىڭ قىممىتىنى كىرگۈزۈڭ
                  </b-form-invalid-feedback>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <template v-if="formData.type === 'image'">
            <b-row>
              <b-col sm="6" md="4">
                <b-form-group
                  id="input-value-image"
                  label="تەڭشەك قىممىتى ئۈچۈن رەسىم تاللاڭ"
                >
                <image-uploader
                  v-model="formData.value"
                  :state="validateState('value')"
                  :width="parseInt(formData.width)"
                  :height="parseInt(formData.height)"
                  :upload-form="{strategy: 'setting'}"
                  accepted-file-types="image/jpeg, image/png, image/jpg, image/gif"
                />
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col sm="6" md="2">
                <b-form-group
                  id="input-value-width"
                  label="رەسىم كەڭلىكى"
                  label-for="value"
                  description="تەڭشەكنىڭ رەسىمىنىڭ كەڭلىكىنى كىرگۈزۈڭ"
                >
                  <b-form-input
                          id="width"
                          size="md"
                          v-model="formData.width"
                          style="width: 100%; direction: ltr;"
                          type="number"
                          placeholder=" ئېگىزلىك قىممىتىنى كىرگۈزۈڭ"
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="6" md="2">
                <b-form-group
                        id="input-value-height"
                        label="رەسىم ئېگىزلىكى"
                        label-for="value"
                        description="تەڭشەكنىڭ رەسىمىنىڭ ئېگىزلىكىنى كىرگۈزۈڭ"
                >
                  <b-form-input
                          id="height"
                          size="md"
                          v-model="formData.height"
                          style="width: 100%; direction: ltr;"
                          type="number"
                          placeholder=" ئېگىزلىك قىممىتىنى كىرگۈزۈڭ"
                  ></b-form-input>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <b-button id="submit" type="submit" variant="primary">
            ساقلاش
          </b-button>
          <b-button class="ml-2" type="reset" variant="danger">
            بىكار قىلىش
          </b-button>
        </b-form>
      </template>
    </portlet>
    </b-overlay>
  </div>
</template>

<script>
  import Portlet from "../../partials/content/Portlet";
  import ImageUploader from "../../partials/layout/ImageUploader";
  import Error from "../../partials/widgets/Error";
  import { validationMixin } from "vuelidate";
  import { required, requiredIf } from "vuelidate/lib/validators";
  import { mapGetters } from 'vuex';
  import ApiService from "../../../common/api.service";
  import handleReject from "../../../common/handleReject";
  import Swal from "sweetalert2";
  import settingMixin from "@m@/setting.mixin";
  export default {
    name: "SingleForm",
    props: {
      action: {
        type: String,
        default: 'create'
      }
    },
    mixins: [validationMixin, settingMixin],
    validations: {
      formData: {
        name: { required },
        title: { required },
        remark: { required },
        type: { required },
        value: { required: requiredIf((formData=>{
          return ! formData.json_value;
        })) },
        source: {
          required: requiredIf((formData)=>{
            return ! formData.value
          })
        }
      }
    },
    components: { Portlet, ImageUploader, Error },
    computed: {
      title(){
        let action = this.action;
        if ( action === 'create' )
          return 'يېڭىدىن تەڭشەك قوشۇش';
        else if ( action === 'edit' )
          return 'تەڭشەك ئۇچۇرى تەھرىرلەش';
      },
      ...mapGetters([ "settingError" ])
    },
    created() {
      if ( this.action === 'edit' )
        this.getCurrentSetting();
    },
    data(){
      return {
        formData: {
          name: '',
          title: '',
          remark: '',
          type: '',
          source: null,
          json_value: null,
          height: 200,
          width: 750,
          value: ''
        },
        formType: [
          { text: 'خەت', value: 'input' },
          { text: 'يەككە تاللانما', value: 'radio' },
          { text: 'كۆپ تاللانما', value: 'checkbox' },
          { text: 'كۆپ قۇرلۇق خەت', value: 'textarea' },
          { text: 'رەسىم', value: 'image' },
        ],
        dynamicType: null,
        preview: null,
        formBusy: false
      };
    },
    methods: {
      onSubmit(){
        this.$v.formData.$touch();
        if (this.$v.formData.$anyError) {
          Swal.fire({
            title: 'ئەسكەرتىش!',
            text: 'تولدۇرۇشقا تېگىشلىك جەدىۋەلنى تۇلۇق تولدۇرۇڭ',
            icon: 'warning',
            confirmButtonText: 'بىلدىم',
          });
          return;
        }
        if ( this.action === 'create' ){
          // this.createSetting();
          this.storeSetting(this.formData)
            .then(()=>{
              return this.$router.replace({name: 'settings.home'});
            })
        }else if ( this.action === 'edit' ){
          // this.editSetting();
          this.updateSettings(this.formData)
            .then(()=>{
              this.success({message: 'تەھرىرلەش مۇۋاپىقىيەتلىك بولدى!'});
            })
            .catch(()=>{
              this.warning({message: 'ئېنىقسىز خاتالىق كۆرۈلدى.'})
            })
        }
      },
      onReset(){
        return this.$router.replace({name: 'setting.home'});
      },
      createSetting(){
        let formData = this.formData;
        this.formBusy = true;
        ApiService.setHeader();
        ApiService.post('/manager/setting', formData)
          .then(response =>{
            this.$store.commit(SET_SETTING_ERROR, null);
            this.formBusy = false;
            this.$bvModal.msgBoxConfirm('يېڭى تەڭشەك قوشۇش مۇۋاپىقىيەتلىك بولدى، تەڭشەك باشقۇرۇشقا يۆتكىلەمسىز ؟', {
              title: 'ئەسكەرتىش',
              okVariant: 'danger',
              okTitle: 'شۇنداق',
              cancelTitle: 'ياق',
              footerClass: 'p-2',
              hideHeaderClose: true,
              centered: true
            })
              .then(value => {
                if ( ! value ) return ;
                return this.$router.push({ name: 'setting.list' });
              })
              .catch(err => {})
          })
          .catch((response) =>{
            this.formBusy = false;
            /*let error = response ? response.hasOwnProperty('data') ?
              response.data.hasOwnProperty('errors') ?
              response.data.errors : response.data : response : null;
              this.$store.commit(SET_SETTING_ERROR, error);*/
            handleReject(this.$store, response, SET_SETTING_ERROR);
          });
      },
      editSetting(){
        let formData = this.formData;
        this.formBusy = true;
        this.$store.dispatch(UPDATE_SETTING, formData)
          .then(response=>{
            this.formBusy = false;
            this.$bvModal.msgBoxConfirm(' تەڭشەك ئۇچۇرى تەھرىرلەش مۇۋاپىقىيەتلىك بولدى، تەڭشەك باشقۇرۇشقا يۆتكىلەمسىز ؟', {
              title: 'ئەسكەرتىش',
              okVariant: 'danger',
              okTitle: 'شۇنداق',
              cancelTitle: 'ياق',
              footerClass: 'p-2',
              hideHeaderClose: true,
              centered: true
            })
              .then(value => {
                if ( ! value ) return ;
                return this.$router.push({ name: 'setting.list' });
              })
              .catch(err => {})
          })
          .catch(response=> {this.formBusy = false;handleReject(this.$store, response, SET_SETTING_ERROR);})
      },
      checkTheTypeValue(e){
        if ( ! ['radio', 'checkbox'].find(value=> value === e ) ){
          this.formData.source = null;
          this.formData.json_value = null;
          this.dynamicType = null;
        }
      },
      generateDynamicType(e){
        let source = this.formData.source;
        if ( ! source ) return;
        let format = source.split(/[(\r\n)\r\n]+/);
        let typeArr = [];
        format.map((item, key, arr)=>{
          let sp = item.split(":");
          let one = { text: sp[0], value: sp[1] };
          typeArr.push(one);
        });
        this.dynamicType = typeArr;
      },
      onUploadSuccess(response){
        this.formData.value = response.path;
        // this.preview = response.url;
        this.$v.formData.$touch();
      },
      validateState(name, parent = null) {
        if ( parent ){
          const { $dirty, $error } = this.$v.formData[parent][name];
          return $dirty ? !$error : null;
        }
        const { $dirty, $error } = this.$v.formData[name];
        return $dirty ? !$error : null;
      },
      getCurrentSetting(){
        let id = this.$route.params.id;
        this.formBusy = true;
        this.$store.dispatch(FIND_SETTING, id)
          .then(response=>{
            this.formData = response.data;
            this.formBusy = false;
          })
          .catch(err=>{this.formBusy = false;})
      },
      onInputForEditor({content}){
        this.formData.value = content;
      }
    }
  }
</script>
